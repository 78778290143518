import React, { useEffect } from "react";

import useNorthstar from "../../hooks/northstarHook";
import { useAuthContext } from "../../contexts/authContext";
import defaultStreamlitThumbnail from "../../assets/northstar/data_science.png";
import { navigate } from "gatsby";
import IEmbeddedApplication from "../../types/embeddedApplications";
import Tiles from "../Tiles";

const ToolsComponent: React.FC = ({}) => {
  const { accessToken, clearIdentity } = useAuthContext();

  const { data: embeddedApps, error: embeddedAppsError } = useNorthstar(
    "/api/embedded-applications",
    accessToken,
    {
      method: "get",
      params: {
        fields: ["title", "description"],
        populate: ["tags", "thumbnail"],
      },
    },
  );

  useEffect(() => {
    let ignore = false;

    if (embeddedAppsError?.status === 401 && !ignore) clearIdentity();

    return () => {
      ignore = true;
    };
  }, [embeddedAppsError?.status]);

  return (
    <Tiles
      items={[
        ...(embeddedApps?.data || []).map(
          (embeddedApplication: IEmbeddedApplication) => {
            return {
              key: `embedded-application:${embeddedApplication.id}`,
              name: embeddedApplication.attributes.title,
              onClick: () => navigate(`/tools/${embeddedApplication.id}`),
              description: embeddedApplication.attributes.description,
              tags: embeddedApplication.attributes.tags?.data,
              thumbnail:
                embeddedApplication.attributes.thumbnailURL ||
                defaultStreamlitThumbnail,
            };
          },
        ),
      ]}
    />
  );
};

export default ToolsComponent;
