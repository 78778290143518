import React from "react";

import BannerComponent from "../../components/Banner";

import NorthstarPageLayout from "../../components/NorthstarPageLayout";
import ToolsComponent from "../../components/Tools";

const Tools: React.FC = () => {
  return (
    <NorthstarPageLayout title="Tools">
      <>
        <BannerComponent
          title={"Tools"}
          description="Interactive data tools allow you to create insights at-scale using our data and models, then easily operationalize those insights​."
        />
        <ToolsComponent />
      </>
    </NorthstarPageLayout>
  );
};

export default Tools;
