import React from "react";
import { IDocumentTag } from "../../types/documents";
import Tile, { TileComponentProps } from "../Tile";

interface TilesProps {
  items: TileComponentProps[];
}

const TilesComponent: React.FC<TilesProps> = ({ items }) => {
  return (
    <div className="pb-6 flex flex-row flex-wrap flex-start">
      {items.map((item) => {
        return (
          <Tile
            key={item.key}
            name={item.name}
            onClick={item.onClick}
            description={item.description}
            tags={item.tags}
            thumbnail={item.thumbnail}
            path={item.path}
            size={item.size}
          />
        );
      })}
    </div>
  );
};

export default TilesComponent;
