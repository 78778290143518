import React from "react";
import Tag, { PillColor } from "../Tag";
import { IDocumentTag } from "../../types/documents";
const byteSize = require("byte-size");

export interface TileComponentProps {
  key: string;
  onClick: () => void;
  name: string;
  description?: string | null;
  tags?: IDocumentTag[];
  thumbnail: string;
  path?: string;
}

const TileComponent: React.FC<TileComponentProps> = ({
  onClick,
  name,
  description = null,
  tags = null,
  thumbnail,
  path = null,
}) => {
  return (
    <div
      className="w-[300px] border-gray-light border rounded-3xl mr-9 mb-8 bg-white cursor-pointer hover:border hover:border-orange shadow-lg"
      onClick={() => onClick()}
    >
      <div className="p-4 bg-blue min-h-[167px] flex justify-center items-center rounded-t-3xl">
        <img
          src={thumbnail}
          alt="img"
          className="object-scale-down h-[70px]"
        ></img>
      </div>
      <div className="p-4 min-h-[180px] flex flex-col">
        <div className="font-bold line-clamp-1">{name}</div>
        {
          <div className="line-clamp-1">
            {path && <div className="text-sm text-gray-500 mr-1">{path}</div>}
          </div>
        }
        {description !== "" ? (
          <div className="line-clamp-3 mt-1">{description}</div>
        ) : (
          <></>
        )}
        <div className="grow"></div>
        <div className="flex flex-wrap justify-left my-2 gap-1">
          {tags &&
            tags
              .sort((a, b) => (a.name < b.name ? -1 : 1))
              .map((tag) => (
                <Tag
                  id={tag.id}
                  name={tag.attributes?.name || tag.name}
                  key={tag.id}
                  pillColor={PillColor.ORANGE}
                />
              ))}
        </div>
      </div>
    </div>
  );
};

export default TileComponent;
